.footer__wrapper{
    padding: 1rem;
    display: flex;
    justify-content: center;
}

.footer__wrapper > p {
    font-size: 20px;
    color: white;
    font-weight: 200;
}

@media only screen and (max-width: 950px){
    .footer__wrapper > p {
        font-size: 15px;
    }
}