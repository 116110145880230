.vineyard__wrapper {
    background-image: url('../assets/webP/vineyard.webp');
    background-size: cover;
    background-position: center;
    padding: 12rem 0 12rem 0;
    margin-bottom: 2rem;
}

.vineyard__text--wrapper {
    width: 66vw;
    margin: 0 auto 0 auto;
    color: white;

    display: grid;
    grid-template-columns: 50% 50%;
}

.vineyard__text--wrapper > h2 {
    grid-column: 2;
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.vineyard__text > p {
    grid-column: 2;
    font-size: 20px;
    font-weight: 300;
}

.vineyard__text {
    grid-column: 2;
    display: flex;
    flex-flow: column;
    gap: 2rem;
}

@media only screen and (max-width: 1300px){
    .vineyard__text--wrapper{
        width: 80vw;
    }
}

@media only screen and (max-width: 950px) {
    .vineyard__wrapper {
        padding: 2rem 2rem 2rem 2rem;
        margin-bottom: unset;
    }

    .vineyard__text--wrapper {
        width: unset;
    }

    .vineyard__text--wrapper > h2 {
        font-size: 50px;
    }

    .vineyard__text > p {
        font-size: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .vineyard__wrapper {
        background-image: url('../assets/webP/vineyard_mobile.webp');
        padding: 1rem;
    }

    .vineyard__text--wrapper {
        display: flex;
        flex-flow: column;
        margin-top: 1rem;
        margin-bottom: 20rem;
    }

    .vineyard__text--wrapper > h2 {
        font-size: 40px;
    }

    .vineyard__text {
        gap: 1rem;
    }
}
