.header__wrapper{
    height: 100vh;
    background-image: url(../assets/webP/banner.webp);
    background-position: center;
    background-size: cover;
    margin-bottom: 2rem;
}

.foreground__wrapper > h1{
    visibility: hidden;
}

.foreground__wrapper{
    display: flex;
    height: 100vh;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
}

.expand{
    width: 3vw;
}

.logo{
    width: 66vw;
    align-self: center;
}

@media only screen and (min-width: 1920px){
    .background__image{
        width: 100vw;
        height: auto;
        grid-row: 1;
        grid-column: 1;
    }
}

@media only screen and (max-width: 950px){
    .header__wrapper{
        margin-bottom: 0;
    }

    .expand{
        width: 5vw;
    }
}