.links__wrapper{
    background: url('../assets/webP/outside.webp') center no-repeat;
    background-size: cover;
    padding: 12rem 0 12rem 0;
    margin-bottom: 2rem;
}

.links{
    display: flex;
    flex-flow: column;
    width: 66vw;
    margin: 4rem auto 5rem auto;
    align-items: flex-end;
    gap: 1rem;
}

.links > a{
    text-align: center;
    width: 20%;
    line-height: 3rem;
    color: white;
    font-size: 20px;
    font-weight: 600;
    border: 2px solid transparent;
    transition: .2s all;
}

.links > a:hover{
    border: 2px solid white;
    background: transparent;
    color: white;
}

.link__fb{
    background: transparent linear-gradient(180deg, #730202 0%, #17A9FC 0%, #0165E1 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

.link__insta{
    background: transparent linear-gradient(90deg, #730202 0%, #FEDA75 0%, #FA7E1E 30%, #D62976 54%, #962FBF 78%, #4F5BD5 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

.link__linkedIn{
    background: #0A66C2 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

.link__list{
    background: transparent linear-gradient(180deg, #730202 0%, #740504 0%, #740504 100%) 0% 0% no-repeat padding-box;
    opacity: 0.8;
}

@media only screen and (max-width: 1300px){
    .links > a {
        width: 40%;
    }
}

@media only screen and (max-width: 950px){
    .links__wrapper{
        padding: 4rem 2rem 4rem 2rem;
        margin-bottom: unset;
    }

    .links{
        margin: unset;
        width: unset;
        gap: .5rem;
    }

    .links > a{
        font-size: 15px;
        line-height: 2rem;
    }
}

@media only screen and (max-width: 550px){
    .links__wrapper{
        background-image: url('../assets/webP/outside_mobile.webp');
        padding: 8rem 1rem 8rem 1rem;
    }

    .links > a {
        width: 60%;
    }
}