.newsletter__wrapper{
    display: flex;
    flex-flow: column;
    width: 66vw;
    margin: 0 auto 2rem auto;
    padding:  2rem;
    background: linear-gradient(180deg, #8C4E03 0%, #8C4E0340 100%);
    border-radius: 1rem;
    color: white;

    text-align: center;
    font-size: 20px;
    align-items: center;
    font-weight: 300;
}

.newsletter__wrapper > h2{
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.newsletter__wrapper > p{
    margin-bottom: 1rem;
}

.newsletter__wrapper > span{
    margin-bottom: 2rem;
}

.form__wrapper{
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 2rem;
    gap: 1rem;
}

.form__wrapper > input{
    font-size: 20px;
    background: none;
    border: none;
    color: white;
    text-align: center;
    width: 30vw;
    text-decoration: underline;

}

.form__wrapper > button {
    padding: 0 5rem 0 5rem;
    border-radius: unset;
    font-size: 20px;
    font-weight: 600;
    line-height: 2rem;
    color: #8C4E03FF;
    background-color: rgba(255 , 255 , 255 , .8);
    border: transparent solid 1px;
    transition: .2s all;
}

.form__wrapper > button:hover{
    border: rgba(255 , 255 , 255 , .8) 1px solid;
    background: none;
    color: white;
    cursor: pointer;
}

.form__wrapper > input:focus{
    outline: none
}

.form__wrapper > input::placeholder{
    color: white;
    opacity: .8;
}

.newsletter__latest > a {
    color: white;
    font-weight: 600;
}

.button__loading{
    border: none !important;
    background: transparent !important;
    color: white !important;
    cursor: pointer;
}

.form__loading{
    height: 2.5rem;
}

@media only screen and (max-width: 1300px){
    .newsletter__wrapper{
        width: 80vw;
    }
}

@media only screen and (max-width: 950px){
    .newsletter__wrapper{
        width: unset;
        border-radius: unset;
        font-size: 15px;
        margin-bottom: unset;
        padding: 2rem;
    }

    .newsletter__wrapper > h2{
        font-size: 50px;
    }

    .form__wrapper > input{
        font-size: 15px;
        width: 50vw;
    }

    .form__wrapper > button{
        font-size: 15px;
        padding: 0 1rem 0 1rem;
        line-height: 1.5rem;

    }
}

@media only screen and (max-width: 550px){
    .newsletter__wrapper{
        align-items: flex-start;
        text-align: left;
        padding: 1rem;
    }

    .newsletter__wrapper > h2{
        font-size: 40px;
    }

    .form__wrapper{
        align-items: flex-start;
    }

    .form__wrapper > input{
        font-size: 15px;
        width: 70vw;
        text-align: left;
    }

    .form__wrapper > button{
        font-size: 15px;
        line-height: 1.5rem;
    }
}

