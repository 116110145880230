.testimonial__wrapper {
    width: 66vw;
    margin: 0 auto 2rem auto;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    align-items: center;
    padding: 1.3rem;
    background: transparent linear-gradient(180deg, #26150B 0%, #26150B40 100%) 0% 0% no-repeat padding-box;
    border-radius: 1rem;
    color: white;
}

.testimonial__text--wrapper {
}

.testimonial__image {
    border-radius: 1rem;
    width: 28vw;
}

.testimonial__text--wrapper > h2 {
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 1rem;
}

.testimonial__text--wrapper > p {
    font-size: 20px;
    font-weight: 300;
}

.testimonial__source {
    font-size: 20px;
    text-align: right;
    line-height: 1.5rem;
    margin-top: 1rem;
}

.name{
    font-weight: 600;
}

.function{
    font-weight: 500
}

.company{
    font-weight: 300;
}

@media only screen and (max-width: 1300px){
    .testimonial__wrapper{
        width: 80vw;
    }

    .testimonial__image{
    }
}

@media only screen and (max-width: 950px){
    .testimonial__wrapper{
        width: unset;
        margin: unset;
        border-radius: unset;
        padding: 2rem;
        background: transparent linear-gradient(180deg, #26150B 0%, #73020240 100%) 0% 0% no-repeat padding-box;
    }

    .testimonial__image{
        border-radius: unset;
    }

    .testimonial__text--wrapper > h2 {
        font-size: 50px;
    }

    .testimonial__text--wrapper > p,
    .testimonial__source > p{
        font-size: 15px;
    }

    .testimonial__source > p{
        line-height: normal;
    }
}

@media only screen and (max-width: 550px){
    .testimonial__wrapper{
        padding: 1rem;
        flex-flow: column;
    }

    .testimonial__text--wrapper > h2{
        font-size: 40px;
    }

    .testimonial__image{
	width: 100%;
    }
}
