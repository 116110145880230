.spotlight__wrapper {
    width: 100vw;
    background-image: url("../assets/webP/wineOfTheMonth.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: white;
    padding-top: 2rem;
    padding-bottom: 4rem;

    margin-bottom: 2rem;
}

.spotlight__title {
    font-weight: 600;
    font-size: 80px;
    text-align: center;
    margin-bottom: 1rem;
}

.content__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 66vw;
    margin: auto;
    grid-gap: 4rem;
}

.content__image--wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    overflow: hidden;
}

.content__image--wrapper > img {
    width: 25vw;
    justify-self: end;

    grid-row: 1;
    grid-column: 1;
}

.content__image--wrapper > .content__image--overlay {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;

    background: #8C5C03 no-repeat padding-box;
    mix-blend-mode: overlay;
    opacity: 1;
    width: 20vw;
    justify-self: end;
}

.content__text--wrapper {
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.content__text--wrapper > h3 {
    font-style: oblique;
    font-size: 50px;
    font-weight: 500;
    margin-bottom: 1rem;
}

.content__text--wrapper > div > div {
    font-size: 20px;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.content__text--wrapper > div > div > ul {
    list-style: disc;
    margin-left: 1rem;
}

.content__text--wrapper > div > div > p  > a {
    color: white;
    font-weight: 600;
}

strong {
    font-weight: bold;
}

.spotlight__italic{
    font-style: italic;
}

.content__wrapper{
    display: block;
}

.upcoming__wrapper{
    width: 100%;
}

.upcoming__image{
    width: 100%;
}

@media only screen
and (max-width: 950px)
and (min-width: 550px) {
    .spotlight__wrapper {
        padding-bottom: 2rem;
        margin-bottom: unset;
    }

    .spotlight__title {
        font-size: 50px;
    }

    .content__image--wrapper > img {
        width: 25vw;
    }

    .content__text--wrapper > h3 {
        font-size: 36px;
    }

    .content__text--wrapper > div > div {
        font-size: 15px;
    }

    .content__image--wrapper > .content__image--overlay {
        width: 25vw;
    }

    .content__wrapper {
        grid-gap: 2rem;
    }
}


@media only screen
and (max-width: 550px) {
    .spotlight__wrapper {
        padding: 1.4rem;
        margin-bottom: unset;
    }

    .spotlight__title {
        font-size: 40px;
        text-align: left;
    }

    .content__wrapper {
        margin: unset;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .content__image--wrapper > img {
        width: 100%;
        align-self: unset;
    }

    .content__image--wrapper > .content__image--overlay {
        width: 100%;
        align-self: unset;

    }

    .content__text--wrapper > h3 {
        font-size: 36px;
    }

    .content__text--wrapper > div > div {
        font-size: 15px;
    }

    .content__wrapper {
        grid-gap: 2rem;
    }
}
