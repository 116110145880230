.contact__wrapper{
    background: linear-gradient(180deg, rgba(38,21,11,1) 0%, rgba(38,21,11,0.25) 100%);
    width: 66vw;
    margin-left: auto;
    margin-right: auto;
    color: white;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 4rem;
    margin-bottom: 2rem;

    border-radius: 1rem;
}

.contact__wrapper > h2{
    text-align: center;
    font-family: 'Elza', sans-serif;
    font-size: 80px;
    font-weight: 600;
    margin: 0 0 2rem;
}

.text__wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin-bottom: 2rem;
}

.text > p, .text > a {
    line-height: 35px;
    font-size: 1.25rem;
    color: white;
    font-weight: normal;
}

.left__wrapper{
    display: flex;
    flex-flow: column;
    text-align: right;
}

.right__wrapper{
    display: flex;
    flex-flow: column;
}



@media only screen
and (max-width: 950px)
and (min-width: 550px){
    .contact__wrapper{
        width: 100vw;
        border-radius: unset;
        margin-bottom: unset;
        padding-top: 2rem;
    }

    .contact__wrapper > h2{
        font-size: 50px;
    }

    .text > p , .text > a{
        font-size: 15px;
    }
}



@media only screen and (max-width: 550px){
    .contact__wrapper{
        width: 100vw;
        border-radius: unset;
        padding: 1.4rem;
        align-items: normal;
        margin-bottom: unset;

    }

    .contact__wrapper > h2{
        font-size: 40px;
        text-align: left;
        margin-bottom: 1.4rem;
    }

    .text__wrapper{
        display: flex;
        flex-flow: column;
        grid-gap: unset;
        margin: unset;
    }

    .text{
        margin-bottom: 1rem;
    }

    .text > p , .text > a{
        font-size: 15px;
        text-align: left;
        line-height: 1.5rem;
    }





}

