.about__wrapper {
    display: grid;
    grid-template-columns: 60% 40%;
    width: 66vw;
    border-radius: 1rem;
    background: transparent linear-gradient(180deg, #8C6645 0%, #8C664540 100%) no-repeat padding-box;
    padding: 1.3rem;
    color: white;
    margin: auto auto 2rem;

}

.about__wrapper > img {
    width: 20vw;
    border-radius: 1rem;
    grid-column: 2;
    grid-row: 1;
    align-self: center;
    justify-self: end;
}

.about__title {
    font-weight: 600;
    font-size: 80px;
    margin-bottom: 1rem;
}

.about__text--wrapper {
    align-self: center;
}

.about__text--content {
    display: flex;
    flex-flow: column;
    gap: 1rem;
}

.about__text--content > p {
    font-size: 20px;
    font-weight: 300;
}

@media only screen and (max-width: 1300px){
    .about__wrapper{
        width: 80vw;
    }

    .about__wrapper > img{
        width: 30vw;
    }
}

@media only screen and (max-width: 950px){
    .about__wrapper{
        border-radius: unset;
        width: 100vw;
        margin: unset;
        justify-content: space-between;
        padding: 1rem 2rem 1rem 2rem;
        grid-template-columns: 55% 45%;
    }

    .about__title{
        font-size: 50px;
    }

    .about__text--content > p{
        font-size: 15px;
    }


    .about__wrapper > img{
        width: 40vw;
        border-radius: unset;
    }
}

@media only screen and (max-width: 550px){
    .about__wrapper{
        display: flex;
        flex-flow: column;
        padding: 1rem;
        margin-bottom: unset;
    }

    .about__title{
        font-size: 40px;
    }

    .about__wrapper > img{
        width: 50vw;
        margin-top: 1rem;
        align-self: end;
    }
}
